/* customToastStyles.css */

.Toastify__toast-container {
    z-index: 9999;
  }
  
  .Toastify__toast {
    font-family: 'Arial', sans-serif;
    border-radius: 8px;
  }
  
  .Toastify__toast--info {
    background: white;
    color: black;
  }
  
  .Toastify__toast--success {
    background: white;
    color: black;
  }
  
  .Toastify__toast--warning {
    background: white;
    color: black;
  }
  
  .Toastify__toast--error {
    background: white;
    color: black;
  }
  